export namespace FileDownloader {
  export function download(data: Blob, filename?: string): void {
    const blob = new Blob([data], { type: data.type });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    if (filename) {
      a.download = filename;
    }

    a.click();
    a.remove();

    window.URL.revokeObjectURL(url);
  }
}
