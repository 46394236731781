import { FileDownloader } from "~/utils/fileDownloader";
import { BOT_STATUS_EXTENDED } from "~/types/recallBot.types";

export function useTranscriptExport() {
  const isDownloading = useState<boolean>(() => false);
  const isDownloadingToVtt = useState<boolean>(() => false);
  const toast = useToast();
  const { meeting } = useMeeting();
  const { bot } = useMeetingBot();
  const { trackEvent, TRACKING_EVENT } = useEventTracker();
  const { transcripts } = useTranscript();

  async function downloadTranscript(meetingId: string) {
    try {
      isDownloading.value = true;
      const response = await useNuxtApp().$http<Blob>(
        `/api/v1/meetings/${meetingId}/export`,
        {
          responseType: "blob",
        },
      );

      FileDownloader.download(
        response,
        bot.value?.metadata.meeting_metadata?.title,
      );

      trackEvent(TRACKING_EVENT.MEETING.DOWNLOADED_TRANSCRIPT);
    } catch (err) {
      toast.add({ title: "Download Failed", color: "red" });
    } finally {
      isDownloading.value = false;
    }
  }

  async function downloadTranscriptToVtt(meetingId: string) {
    try {
      isDownloadingToVtt.value = true;
      const response = await useNuxtApp().$http<Blob>(
        `/api/v1/meetings/${meetingId}/vtt_export`,
        {
          responseType: "blob",
        },
      );

      FileDownloader.download(
        response,
        bot.value?.metadata.meeting_metadata?.title,
      );

      trackEvent(TRACKING_EVENT.MEETING.DOWNLOADED_TRANSCRIPT_TO_VTT);
    } catch (err) {
      toast.add({ title: "Download Failed", color: "red" });
    } finally {
      isDownloadingToVtt.value = false;
    }
  }

  const hasInCallRecording = computed(() =>
    bot.value?.metadata.status_changes.some(
      (i) => i.code === BOT_STATUS_EXTENDED.IN_CALL_RECORDING,
    ),
  );
  const hasCallEnded = computed(() =>
    bot.value?.metadata.status_changes.some(
      (i) => i.code === BOT_STATUS_EXTENDED.CALL_ENDED,
    ),
  );
  const isDownloadAvailable = computed(() => {
    if (transcripts.value.length < 1) {
      return false;
    }
    if (meeting.value?.end_at) {
      return true;
    }
    return hasInCallRecording.value && hasCallEnded.value;
  });
  return {
    downloadTranscript,
    isDownloading,
    isDownloadAvailable,
    downloadTranscriptToVtt,
    isDownloadingToVtt,
  };
}
