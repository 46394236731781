<script setup lang="ts">
const {
  botRecordStartedAt,
  elapsedDuration,
  remainingDuration,
  isLiveMeeting,
  isFreeTierMeeting,
} = useMeeting();

const formattedElapsedTime = computed(() => {
  const diffDuration = elapsedDuration.value;
  if (diffDuration.asHours() < 1) {
    return `${String(diffDuration.minutes()).padStart(2, "0")}:${String(diffDuration.seconds()).padStart(2, "0")}`;
  } else {
    return `${String(Math.floor(diffDuration.asHours())).padStart(2, "0")}:${String(diffDuration.minutes()).padStart(2, "0")}:${String(diffDuration.seconds()).padStart(2, "0")}`;
  }
});

const formattedRemainingTime = computed(() => {
  const diffDuration = remainingDuration.value;
  if (diffDuration.asMilliseconds() <= 0) {
    return "00:00";
  }
  return `${String(diffDuration.minutes()).padStart(2, "0")}:${String(diffDuration.seconds()).padStart(2, "0")}`;
});
</script>

<template>
  <span v-if="isLiveMeeting && botRecordStartedAt">
    <span
      v-if="isFreeTierMeeting"
      class="text-xs font-semibold text-gray-700 dark:text-gray-100"
    >
      {{ formattedRemainingTime }}
    </span>
    <span v-else class="text-xs font-semibold text-gray-700 dark:text-gray-100">
      {{ formattedElapsedTime }}
    </span>
  </span>
</template>
