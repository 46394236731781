<script setup lang="ts">
import type { StyleValue } from "vue";

const props = defineProps<{
  title: string;
  containerClass?: string;
  containerStyle?: StyleValue;
}>();
</script>

<template>
  <section class="pt-2">
    <header class="flex items-center justify-between gap-3 pr-3 sm:pr-5">
      <h2 class="text-xs font-semibold uppercase">
        {{ props.title }}
      </h2>

      <slot name="headerRight" />
    </header>

    <div class="pb-2 pt-3" :class="containerClass" :style="containerStyle">
      <slot />
    </div>

    <div>
      <slot name="footer" />
    </div>
  </section>
</template>
